import content from '../../content/get-involved'
import LinkComponent from '../LinkComponent/LinkComponent'
import Image from '../Image/Image'
import './GettingInvolvedHero.scss'
import { StaticImage } from 'gatsby-plugin-image'

const { title, description, cta, highlights } = content.hero

const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

export default function GettingInvolvedHero() {
  return (
    <section
      className="GettingInvolvedHero Hero__Background__Container"
      data-testid="getting-involved-hero"
    >
      <StaticImage
        src={HERO_BACKGROUND}
        alt=""
        loading="eager"
        placeholder="none"
        quality={100}
        objectPosition="top left"
        className="Hero__Background__Image Sticky__Image"
      />
      <div className="GettingInvolvedHero__container section-lg">
        <div className="GettingInvolvedHero__main-content">
          <div className="GettingInvolvedHero__info">
            <h1>{title}</h1>
            <p>{description}</p>
            <LinkComponent
              addClass="Button__primary--blue GettingInvolvedHero__cta"
              href={cta.link}
            >
              {cta.label}
            </LinkComponent>
          </div>
          <div className="GettingInvolvedHero__highlights-content">
            <h2>{highlights.title}</h2>
            <ul className="GettingInvolvedHero__highlights">
              {highlights.items.slice(0, 4).map((item, index) => (
                <li key={`highlight--${title}-${index}`}>
                  <LinkComponent
                    addClass="GettingInvolvedHero__highlight "
                    href={item.link}
                  >
                    <div className="GettingInvolvedHero__highlight-image">
                      <Image src={item.image} alt="" />
                    </div>
                    <div className="GettingInvolvedHero__highlight-info">
                      <p>{item.date}</p>
                      <h3>{item.title}</h3>
                    </div>
                  </LinkComponent>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
